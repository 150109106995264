<template>
  <div class='NoticeManage public-studioAdmin-body'>
    <StudioAdminTabs :list="tabs"></StudioAdminTabs>
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop">
        <div class="lBox public-form-studioAdmin">
          <el-input v-model="form.search_name" placeholder="请输入关键词" class="input"></el-input>
          <el-button type="primary" @click="init()">查询</el-button>
          <el-button @click="clearData()">重置</el-button>
        </div>
        <div class="rBox">
          <el-button class="btn" @click="toPush()">发布公告</el-button>
        </div>
      </div>
      <!-- 表格 -->
      <div class="tableList">
        <el-table :data="tableData" :stripe="true" class="public-table-studioAdmin">
          <el-table-column label="序号" align="center" width="70">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="公告标题" align="center" >
            <template slot-scope="scope">
              <div class="studioAdmin-info-text">
                <p class="info-title">{{ scope.row.title }}</p>
                <span class="info-labelTop" v-if="scope.row.top == 1">{{ scope.row.top == 1 ? "置顶" : "" }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="user_name" label="发布人" align="center" width="90" />
          <el-table-column prop="view_count" label="浏览量" align="center" width="70" />
          <el-table-column prop="create_time" label="发布时间" align="center" width="180" />
          <el-table-column prop="date" label="操作" align="center" width="230">
            <template slot-scope="scope">
              <el-button type="text" class="text-color-blue" @click="toDetail(scope.row.id)">查看</el-button>
              <el-button type="text" class="text-color-blue" @click="toUpdata(scope.row.id)">修改</el-button>
              <el-button type="text" class="text-color-red" @click='toDelete(scope.row.id, scope.row.user_id)'>删除</el-button>
              <el-button type="text" class="text-color-blue" @click="top(scope.row)">{{ scope.row.top == 2 ? "置顶" : "取消置顶" }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block" v-show="total">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input: '',
      tableData: [],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10,
        teaching_studio_id: '',
        search_name: '',
      },
      //导航
      tabs: [
        {
          label: "通知公告管理",
          count: 0
        },
      ],
    }
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    this.init();
  },
  computed: {
  },
  methods: {

    //重置数据
    clearData() {
      this.form.page = 1;
      this.form.limit = 10;
      this.form.search_name = '';
      //初始化列表数据
      this.init();
    },


    //删除公告
    toDelete(id, uid) {
      let obj = {
        id: id,
        user_id: uid
      }
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.delete("index/teachingStudioNotice/deleteTeachingStudioNotice", { params: obj }).then(() => {
          //重新获取数据
          this.init();
          this.$message.success("删除成功")
        })

      }).catch(() => {
        this.$message.warning("取消删除")
      });



    },
    //修改公告
    toUpdata(id) {
      this.$router.push({
        path: '/PushNotice',
        query: {
          nid: id
        }
      })
    },
    //跳转详情
    toDetail(uid) {
      let routeData = this.$router.resolve({
        path: "/noticeDetail",
        query: {
          id: uid
        },
      });
      window.open(routeData.href, '_blank');
    },
    //通知公告列表
    init() {
      this.form.teaching_studio_id = localStorage.getItem("studioId");
      this.$axios.get("index/teachingStudioNotice/listsTeachingStudioNotice", {
        params: this.form
      }).then((res) => {
        this.tableData = res.data.data.data;
        this.total = res.data.data.total;
      })
    },
    //发布公告
    toPush() {
      this.$router.push('/PushNotice')
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.init();
    },
    //推荐回调
    top(e) {
      let params = {
        id: e.id,
        top: e.top == 2 ? 1 : 2
      }
      this.$axios
        .put("teachingStudioNotice/top", params)
        .then(() => {
          this.init();
        });
    },
  },
}
</script>
<style lang="less" scoped>
.NoticeManage {
  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
  }

  //内容盒子
  .contentBox {
    padding: 26px 26px;

    .selectTop {
      display: flex;
      justify-content: space-between;

      .lBox {
        .input {
          width: 216px;
          margin-right: 10px;
          margin-left: 0;
        }
      }

      .rBox {
        .btn {
          background: #ff8201;
          border: 1px solid #ff8201;
          border-radius: 4px;
          display: flex;
          align-items: center;
          color: #fff;
        }
      }
    }

    //样式
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
